<nav class="navbar navbar-expand-lg navbar-default">
  <div class="container-fluid">
    <div class="navbar-header navbar-brand">
      <a href="#">
        <img src="../../assets/images/PEI-Logo.jpg" alt="Pezzoni Engineering logo" height="100px">
      </a>
    </div>

    <div class="justify-content-end">
      <ul class="nav navbar-nav">
        <!-- <li routerLinkActive="active"><a routerLink="/home">HOME </a></li> -->
        <li routerLinkActive="active"><a routerLink="/about"> ABOUT </a></li>
        <li routerLinkActive="active"><a routerLink="/services"> SERVICES </a></li>
        <!-- <li routerLinkActive="active"><a routerLink="/markets"> MARKETS </a></li> -->
        <li routerLinkActive="active"><a routerLink="/contact"> CONTACT</a></li>
        <!-- <li><a style="cursor: pointer;" (click)="onSaveData()">Save Data</a></li> -->
      </ul>
    </div>
  </div>
</nav>
