import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Message } from './message.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ContactService {
  private contactURL = environment.apiUrl + 'contact';

  constructor(private http: HttpClient) {}

  sendMessage(name: string, email: string, subject: string, description: string) {
    const contactMessage: Message = {
      name: name,
      email: email,
      subject: subject,
      description: description
    };

    this.http.post<{ message: string, sentMessage: Message }>(this.contactURL, contactMessage)
      /*
       *  We use '.toPromise()' here because the request is transactional, meaning it will request
       *    data once from the server, wait for a response, and then the request can be marked as complete
       *
       *  If we want to request a stream of data, i.e. getting locations of moving vehicles, then
       *    we would use a Subscription and use the '.subscribe()' method instead.
       *
       *  Promises are transactional!  Don't use subscribe here...
       */
      .toPromise()
      .then((responseData) => {
      // .subscribe((responseData) => {
        console.log(responseData.message);
        // Client-side success message here
      });
  }
}
