<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <img src="../../assets/images/utilities/utility-main.jpg" alt="Utility Station" width="100%" />
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h2>Distribution</h2>
        <p>
            Pezzoni Engineering has extensive experience in designing power distribution systems ranging from low voltage 
            underground to 17kV overhead construction. We have worked with several customers on capacitor installations, 
            system capability studies, contingency plans, and infrastructure improvement projects. We provide the following 
            services:
        </p>
        
        <ul>
            <li>Construction Standards</li>
            <li>Design Standards</li>
            <li>Overhead Line Extensions</li>
            <li>New Customer Utility Service</li>
            <li>Distribution Planning Services</li>
            <li>SCADA</li>
            <li>Metering</li>
            <li>Photovoltaic Systems</li>
            <li>Motor Starting Analysis</li>
            <li>Transient Stability Analysis</li>
            <li>Generator Start-Up</li>
            <li>Unbalanced Load Flow</li>
            <li>Optimal Power Flow</li>
        </ul>

        <h2>Arc Flash</h2>
        <p>
            In 2000, the introduction of NFPA 70E led to the requirement that all Healthcare, Industrial and Commercial 
            buildings must provide Arc Flash Hazard Analysis before a person approaches any exposed electrical conductor 
            or circuit part that has not been placed in an electrically safe work condition. In 2009, the requirement 
            was expanded to include equipment labeling for Arc Flash Hazard Labels at each piece of new and existing 
            electrical distribution equipment. This requirement is intended to protect individuals who may come in contact 
            with energized equipment. Since the introduction of the NFPA 70E, Miller-Pezzoni & Associates, Inc. has been 
            spreading the word about the dangers Arc Flashes can pose to employees and their employers, and have been helping 
            clients bring their facilities into compliance. Our Arc Flash Analysis services include:
        </p>
        
        <ul>
            <li>AC & DC Arc Flash Analysis</li>
            <li>Equipment Labeling in Accordance with NFPA 70E and ANSI Z535 - Weather resistant labels guaranteed for 5 years</li>
            <li>Short Circuit Analysis – ANSI and IEC</li>
            <li>Load Flow Analysis Arc Flash Reports w/ weather resistant labels guaranteed for 5 years</li>
        </ul>

        <h2>Substations</h2>
        <p>
            Pezzoni Engineering has designed several substations for a wide variety of customers and voltages. Aside from 
            designing substations, we offer support and improvement designs to existing substations with the purpose of 
            allowing our customer to maximize their electrical distribution system. In our designs we provide proper 
            coordination and specifications. It is our standard to provide the customer with construction-ready designs and 
            continued support during and after the construction of the station. We also offer professional designs for the following:
        </p>
        
        <ul>
            <li>Battery Discharge & Sizing</li>
            <li>DC Load Flow Analysis</li>
            <li>DC Short Circuit Analysis</li>
            <li>DC Control Systems</li>
            <li>Breaker Replacements</li>
            <li>Transformer Upgrades</li>
            <li>Relay Replacements</li>
            <li>CT Replacements</li>
            <li>Standby Power Systems</li>
            <li>Construction Details and Standards</li>
            <li>Utility Synced Generation</li>
            <li>SCADA</li>
            <li>Transformer MVA Sizing</li>
            <li>Transformer Tap Optimization</li>
        </ul>

        <h2>Contract Employee Placement</h2>
        <p>
            Pezzoni Engineering has the ability to provide short term and long term contract employees ranging from Engineering 
            Technicians to Degreed Engineers. We currently have several employees contracted with various utility companies to 
            add to their team. We contract well rounded and educated employees that have the ability and knowledge needed to 
            complete projects at the utility level with minor training needed in your utility company’s operation and software 
            to hit the ground running and help get you back on track to meet project goals and timelines. Utility companies 
            have found this service very helpful to fill the void when staffing needs arise. For this service, we work with 
            the utility company to come to an agreement on terms that work best for the utility company.
        </p>
    </div>
</div>
