<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <img src="../../assets/images/services-main-image.jpg" alt="Gamma-Knife" width="100%" />
    </div>
    <div class="col-md-6 col-lg-6">
        <h2>Markets</h2>
        <p>
            We at Pezzoni Engineering offer Electrical Engineering and Design services across a wide variety of market sectors, 
            including the following specialties:
        </p>
        
        <h3>Healthcare</h3>
        <p>
            Healthcare design has been our specialty since our founding 30 years ago. Our extensive experience in all segments of 
            Healthcare design will ensure competent and creative design from complete Hospitals to remodels and technology upgrades. 
            Our 30 years of experience with OSHPD will streamline approvals and ensure on-time delivery of your project. Our 
            widespread healthcare experience includes complete Hospitals, Nursing Homes, Medical Office Buildings and highly 
            specialized Cancer Treatment Facilities such as Cyber-Knife, PET/CT Scanners, Linear Accelerators and other state 
            of the art systems throughout the Country. All of our engineers and support staff have a thorough understanding of 
            the specialty code requirements associated with Healthcare building requirements, and demonstrated comprehension of 
            the complex California Healthcare Building permit process (OSHPD). <a routerLink="/gallery/healthcare">Healthcare Projects Gallery</a>
        </p>
        
        <h3>Education</h3>
        <p>
            Our firm has been involved with educational building projects of all shapes and sizes, from elementary schools to 
            Public and Private Universities. We have designed projects as extensive as entire power, lighting, fire alarm and 
            data systems for entire campuses, and site lighting for parking areas, walkways and sporting fields, and we have 
            also designed smaller projects including remodels of single classrooms. Our engineers are very competent in the 
            code requirements for educational building and have a thorough understanding of the Educational Building Permit 
            process (DSA). <a routerLink="/gallery/education">Educational Projects Gallery</a> Our knowledge and experience with cutting edge 
            energy conservation techniques will ensure your educational facility will be energy efficient and contain the 
            latest in technology innovations.
        </p>
        
        <h3>Commercial Facilities</h3>
        <p>
            Pezzoni Engineering has designed a variety of power, lighting, data and monitoring systems for commercial 
            clients over many years. The projects include new buildings, shell spaces, tenant improvements and site 
            illumination throughout California. Our experience spans a cross section of this industry including retail, 
            multi-use office complexes, auto dealerships and shopping centers. 
            <a routerLink="/gallery/commercial">Commercial / Industrial Projects Gallery</a>
        </p>
    </div>
    <div class="col-md-6 col-lg-6">
        <h3>Industrial / Manufacturing</h3>
        <p>
            Pezzoni Engineering has designed significant industrial facilities worldwide over the past 30 years. We have 
            extensive experience in the food processing industry, process engineering and research facilities. Satisfied 
            clients include the following industry leaders:
        </p>
        
        <p>Lawrence Livermore National Laboratories</p>
        <p>Gallo Winery</p>
        <p>National Est. Food Industries – Riyad, Saudia Arabia</p>
        <p>General Foods</p>
        <p>Kraft Foods</p>
        <p>Leprino Cheese Company</p>
        <p>Banquest Foods</p>
        <p>Shell Research</p>
        <a routerLink="/gallery/commercial">Commercial / Industrial Projects Gallery</a>
        
        <h3>Governmental / Municipal Infrastructure / Civic</h3>
        <p>
            Our experience and relationships with public agencies is second to none, largely due to our thorough 
            understanding of the specialized requirements and budgetary limits of Governmental projects. This 
            experience is enhanced by our understanding of these projects from the Client’s perspective, as our 
            Principal and Founder had over 11 years as a City Electrical Engineer prior to founding our firm. In 
            addition to our many projects in Stanislaus County, we have designed projects for municipalities, 
            County and State agencies throughout California including Water Treatment Facilities, Public Parks and 
            Recreations Areas, Community Centers, Local Government Office Training Facilities, Police Stations, 
            Fire Stations and County Jails. 
            <a routerLink="/gallery/civic">Municipal Projects Gallery</a>
        </p>
        
        <h3>Utilities</h3>
        <p>
            Pezzoni Engineering has extensive experience with the Utility Companies, and their varying needs. Please 
            take a look at our Utility Services page. 
            <a routerLink="/utilities">Utility Services</a>
        </p>        
    </div>
</div>