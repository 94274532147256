   
<div class="row project-detail">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h3>{{ project.name }}</h3>
        <p>{{ project.description }}</p>
        <br/>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <img 
            src='{{ image.path }}' 
            alt="{{ image.altText }}"
            *ngFor="let image of project.images">
        </div>
        <br/>
    </div>
</div>