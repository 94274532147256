
<div class="container root">

  <app-nav></app-nav>

  <router-outlet></router-outlet>

</div>







