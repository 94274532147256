<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <img src="../../assets/images/services-main-image.jpg" alt="Gamma-Knife" width="100%" />
    </div>
    <!-- <div class="col-md-6 col-lg-6"> -->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h2>Services</h2>
        <h3>Electrical Engineering</h3>
        <p>
            At Pezzoni Engineering, we offer a wide variety of Electrical Engineering and Design services
            across an extensive range of market sectors. Our expertise extends to services across the industry
            including the following:
        </p>
    </div>
    <div class="col-md-6 col-lg-6">
        <ul class="outer-list">
            <li>
                Power Distribution Design
                <ul class="inner-list">
                    <li>Low Voltage ( &lt; 600V )</li>
                    <li>Medium Voltage ( 1kV - 35kV )</li>
                    <li>Mission Critical Systems</li>
                </ul>
            </li>
            <li>
                Onsite Power Generation
                <ul class="inner-list">
                    <li>Standby Generation Systems</li>
                    <li>Photovoltaic Systems</li>
                    <li>Battery / UPS Systems</li>
                    <li>Microgrid Solutions</li>
                </ul>
            </li>
            <li>
                Lighting Design
                <ul class="inner-list">
                    <li>Indoor and Outdoor Lighting</li>
                    <li>Controls Design</li>
                    <li>Photometric Analysis</li>
                    <li>Energy Compliance Documentation</li>
                </ul>
            </li>
            <li>
                Life Safety Systems
                <ul class="inner-list">
                  <li>Fire Alarm System Design</li>
                  <li>Nurse Call System Design</li>
                  <li>Mass Notification System Design</li>
                </ul>
            </li>
        </ul>
    </div>
    <div class="col-md-6 col-lg-6">
        <ul class="outer-list">
          <li>
              Automation Design
              <ul class="inner-list">
                  <li>SCADA Systems</li>
                  <li>Motor Control Design</li>
                  <li>Preliminary Control Panel Design</li>
                  <li>Motor Control Centers</li>
              </ul>
          </li>
          <li>
              Data/Communications Systems
              <ul class="inner-list">
                  <li>Data Network ( WAN / LAN )</li>
                  <li>Wireless Network ( WLAN )</li>
                  <li>Radio Systems</li>
              </ul>
          </li>
          <li>
              Low Voltage Systems
              <ul class="inner-list">
                  <li>Access Control Systems</li>
                  <li>Intrusion Detection Systems</li>
                  <li>Intercom Paging Systems</li>
                  <li>Audio / Visual Systems</li>
                  <li>Surveillance Camera Systems</li>
              </ul>
          </li>
          <li>
              Green Building Design
              <ul class="inner-list">
                <li>Green Building Design Standards</li>
                <li>Ultra-Energy Efficient Designs</li>
              </ul>
          </li>
        </ul>
    </div>
    <!-- </div>
    <div class="col-md-6 col-lg-6">
        <h3>Arc Flash Analysis</h3>
        <p>
            In 2005, the introduction of NFPA 70E led to the requirement that all Healthcare, Industrial
            and Commercial buildings must provide Arc Flash warning labels at each piece of new and existing
            electrical distribution equipment. This requirement is intended to protect individuals who may
            come in contact with energized equipment.. For the past three years, we here at Pezzoni Engineering
            have been spreading the word about the dangers Arc Flashes can pose to employees and their employers,
            and have been helping clients bring their facilities into compliance. Our Arc Flash Analysis services include:
        </p>
        <ul>
            <li>Arc Flash Analysis</li>
            <li>Equipment Labeling in Accordance with NFPA 70E</li>
            <li>Arc Flash Information and Safety Training Seminar</li>
            <li>Coordination Studies to improve power system reliability</li>
        </ul>
        <p>
            For a more in-depth explanation of the dangers of Arc Flash, and what Pezzoni Engineering can do
            to help protect your employees and your facility from costly Arc Flash damage, please visit our
            <a href="#">Dangers of Arc Flash Page</a>.
        </p> -->

    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h3>Additional Consultation Services</h3>
        <ul>
            <li>
                Master-planning
                <ul>
                    <li>Feasibility Studies</li>
                    <li>Electrical Cost Estimating and Cost Projections</li>
                    <li>Scoping Studies</li>
                </ul>
            </li>
            <li>Construction Administration</li>
            <li>Investigative/Expert Witness</li>
        </ul>
    </div> -->
</div>
