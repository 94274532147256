<!-- <div class="row header">
</div> -->
<br/>
<div class="row header">
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
        <h2>Modesto</h2>
        <p>1150 9th Street, Suite #1415</p>
        <p>Modesto, CA 95354</p>
        <p>phone: (209) 554-4602</p>
    </div>

    <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 contact">
        <form (ngSubmit)="onSubmit()" #f="ngForm">
            <div class="row">
                <div class="col-xs-12">
                    <h2>Contact Form</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <label for="name">Name<span class="requirementIndicator">*</span></label>
                    <input
                        type="text"
                        id="name"
                        class="form-control"
                        ngModel
                        name="name"
                        placeholder="Name"
                        size="100%"
                        required
                    >
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <label for="email">Email<span class="requirementIndicator">*</span></label>
                    <span class="help-block" *ngIf="!email.valid && email.touched">
                        Please enter a valid email.
                    </span>
                    <input
                        type="text"
                        id="email"
                        class="form-control"
                        ngModel
                        name="email"
                        required
                        email
                        #email="ngModel"
                        placeholder="Email"
                        size="100%"
                    >
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <label for="subject">Subject</label>
                    <input
                        type="text"
                        id="subject"
                        class="form-control"
                        ngModel
                        name="subject"
                        placeholder="Subject"
                        size="100%"
                    >
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <label for="description">Message<span class="requirementIndicator">*</span></label>
                    <textarea
                        id="description"
                        class="form-control"
                        ngModel
                        name="description"
                        required
                        rows="12"
                        placeholder="Please include as much detail as possible."
                        cols="100%">
                    </textarea>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <button
                        type="submit"
                        class="btn btn-light"
                        [disabled]="!f.valid">
                        Send Message
                    </button>
                </div>
            </div>
        </form>
    </div> -->
</div>
