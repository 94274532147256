<div class="row header">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <br/>
        <h2>About Pezzoni Engineering</h2>
                <p>
                  Founded in 2019, Pezzoni Engineering is a progressive electrical engineering firm dedicated to constantly providing our
                  clients with high quality and timely service.  Our philosophy is to listen the Client to understand their needs,
                  expectations, and build a long lasting relationship.
                </p>

                <p>
                  Our Modesto-based staff consists of a team of highly skilled professional personnel with many years of combined
                  engineering experience sharing a deep commitment for client satisfaction through engineering excellence.  Our competent
                  team of personnel work closely together to provide a full-service engineering services tailored to specific project
                  needs.  We offer a complete range of electrical engineering services, the scope of which includes electrical engineering
                  from the preliminary study phase through project construction administration.  Specialties include power distribution,
                  automation controls, lighting design, information technologies systems, and other communications systems design services.
                  Our project experience spanning 37 years encompasses a thorough cross-section of the industry.
                </p>

                <p>
                  Pezzoni Engineering, Inc. incorporates state-of-the-art design techniques based upon practical and cost-effective
                  solutions for the client. Additionally, our many years of experience in the design of municipal and other governmental
                  projects provides us with a unique and valuable perspective into the specialized needs of governmental clients rarely
                  found with consulting engineering firms. We are affiliated with several Professional Societies including:
                </p>
                <ul>
                  <li>
                    Institute of Electrical and Electronics Engineers (IEEE)
                  </li>
                  <li>
                    National Fire Protection Association (NFPA)
                  </li>
                  <li>
                    National Society of Professional Engineers (NSPE)
                  </li>
                  <li>
                    Building Industry Consulting Service International (BICSI)
                  </li>
                </ul>

                <p>
                  Our commitment to remain current with technology enables us to provide timely design completion specifying the most
                  up-to-date methods and materials thus effectuating cost-effective electrical engineering services.
                </p>

    </div>
</div>
<h2>Principal</h2>
<div class="row header">
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
        <img class="bio" src="../../assets/images/kevin.jpg" alt="Kevin Pezzoni, President">
        <h6 class="bio">Kevin L. Pezzoni, P.E.</h6>
        <p class="bio"><b>President</b></p>
        <p>
          Kevin Pezzoni is a Registered Professional Electrical Engineer in the States of California, Arizona and Nevada and is President
          of Pezzoni Engineering.  He has over 20 years of experience comprised of 12 years as a principal.  Mr. Pezzoni’s
          extensive experience in the design of complex electrical systems spans 20 years, with a specialty in healthcare, critical infrastructure,
          commercial and related facilities engineering. This extensive design experience along with his experience in project management has
          enabled Mr. Pezzoni to develop techniques to streamline the design and approval process while designing cost effective and energy
          efficient systems.
        </p>
        <p>
          After Graduating from California Polytechnic State University, San Luis Obispo in 1996, Mr. Pezzoni continued his career as a designer
          for Gregg E. Miller & Associates.  In 2002 he became a principal in the firm, now known as Miller-Pezzoni & Associates, Inc.
          Now in 2019 Mr. Pezzoni will proceed to continue the quality of service its clients received from Miller Pezzoni & Associates into
          Pezzoni Engineering.
        </p>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
      <h6>Education</h6>
      <ul>
        <li>California Polytechnic State University, San Luis Obispo</li>
        <li>B.S. Aeronautical Engineering - 1996</li>
      </ul>
      <ul>
        <li>California Polytechnic State University, San Luis Obispo</li>
        <li>M.S. Electrical Engineering - incomplete</li>
      </ul>

      <h6>Registered Electrical Engineer</h6>
      <ul>
        <li>California No. 16269, 2000</li>
        <li>Arizona No. 53663, 2012</li>
        <li>Nevada No. 021846, 2012</li>
      </ul>

      <h6>Professional Associations</h6>
      <ul>
        <li>Institute of Electrical and Electronics Engineers</li>
        <li>National Society of Professional Engineers</li>
        <li>National Fire Protection Association Building</li>
        <li>Industry Consulting Service International</li>
      </ul>

      <h6>Experience</h6>
      <ul>
        <li>Pezzoni Engineering, Inc.</li>
        <li>President:  Feb. 2019 - Present</li>
      </ul>
      <ul>
        <li>Miller-Pezzoni & Assoc., Inc.</li>
        <li>Vice President:  2002 - Feb. 2019</li>
      </ul>
      <ul>
        <li>Gregg E. Miller & Assoc., Inc.</li>
        <li>Drafter/Designer:  1993 - 2002</li>
      </ul>
    </div>
</div>
<h2>Project Manager</h2>
<div class="row header">
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
        <img class="bio" src="../../assets/images/mike.jpg" alt="Michael Stevens, Project Manager">
        <h6 class="bio">Michael Stevens</h6>
        <p class="bio"><b>Project Manager</b></p>
        <p>
          Michael Stevens is a Project Manager with Pezzoni Engineering.
        </p>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
      <h6>Education</h6>
      <ul>
        <li>National Education Center, Arizona</li>
        <li>Occupational Associate Degree,</li>
        <li>Electro-Mechanical and Computer Aided Drafting - 1984</li>
      </ul>

      <h6>Experience</h6>
      <ul>
        <li>Pezzoni Engineering, Inc.</li>
        <li>Feb. 2019 - Present</li>
      </ul>
      <ul>
        <li>Miller-Pezzoni & Assoc., Inc.</li>
        <li>2017-2019</li>
      </ul>
      <ul>
        <li>Michael Stevens Electrical Design Group</li>
        <li>2014 - 2017</li>
      </ul>
      <ul>
        <li>Koch, Chun, Knoblock & Associates, Inc</li>
        <li>1988 - 2014</li>
      </ul>
    </div>
</div>
<!-- <div class="row header">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <br/>
        <h2>Careers with Pezzoni Engineering</h2>
    </div>
</div> -->
